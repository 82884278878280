



import { Component, Vue } from 'vue-property-decorator';
import StepNavi from '../../../../components/contents/stepNavi/StepNavi.vue';

@Component({
  components: {
    StepNavi,
  },
})
export default class AccountEditMailSend extends Vue {

  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  get fromPath(): string|null {
    return sessionStorage.getItem('fromPath');
  }
}
